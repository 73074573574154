import { useEffect, useState } from "react";
import * as React from "react";
import ConfigProvider from "../../helpers/config/config-provider";
import { config } from "@financialcanvas/util/config";

export type ConfigLoaderProps = {
  children: React.ReactChild;
};

/** Defers rendering its children until configuration is available. */
export const ConfigLoader = ({ children }: ConfigLoaderProps) => {
  const [localConfig, setLocalConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchConfig() {
      const result = await fetch("/config.json", { cache: "no-cache" });
      const newConfig = await result.json();
      for (const prop in localConfig) {
        delete config[prop];
      }

      for (const prop in newConfig) {
        config[prop] = newConfig[prop];
      }

      setIsLoading(false);
      setLocalConfig(newConfig);
    }

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }

  return <ConfigProvider config={localConfig}>{children}</ConfigProvider>;
};
