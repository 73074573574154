import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./main.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "typeface-roboto";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "@financialcanvas/util/i18n";

import { lazy, Suspense } from "react";

import { ConfigLoader } from "./app/components/config-loader";
import { createRoot } from "react-dom/client";

const App = lazy(() => import("./app/app"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ConfigLoader>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </ConfigLoader>
);
