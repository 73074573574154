import { createContext, useContext } from "react";

import {
  Branding,
  Config,
  DashboardConfig,
  Group,
} from "@financialcanvas/util/types";

import buildConfig from "./config.json";

export const config: Config = {
  id: "INVALID",
  STUDIO_URI: "INVALID",
  STUDIO_S3_BUCKET: "INVALID",
  dashboards: [],
  title: "Financial Canvas Studio",
  branding: "sciurus",
};

export const useStudioFeatureFlag = (flag: string) => {
  if (!("STUDIO_FEATURE_FLAGS" in config)) {
    return false;
  }

  if (!(config.STUDIO_FEATURE_FLAGS && flag in config.STUDIO_FEATURE_FLAGS)) {
    return false;
  }

  return config["STUDIO_FEATURE_FLAGS"][flag];
};

export function getBranding(): Branding {
  return buildConfig.branding[config.branding];
}

export function getGroups(): Group[] {
  return config.groups.map((id) => ({
    id: id,
    ...buildConfig.groups[id],
  }));
}

export function getDashboardConfigs() {
  const buildConfigDashboards = Object.keys(buildConfig.dashboards);

  return config.dashboards
    .filter((dashboard) => buildConfigDashboards.includes(dashboard))
    .map((dashboard) => buildConfig.dashboards[dashboard]);
}

export function getDashboardConfig(dashboardName?: string): DashboardConfig {
  const buildConfigDashboards = Object.keys(buildConfig.dashboards);

  return (
    config.dashboards
      .filter((dashboard) => buildConfigDashboards.includes(dashboard))
      .map((dashboard) => buildConfig.dashboards[dashboard])
      .find((d) => d.value === dashboardName) || {
      value: "",
      label: undefined, // Several components, including the header rely on this being undefined
      source: "",
      component: "",
      keys: [],
    }
  );
}

export function getColorScheme() {
  const colors: { [index: string]: string } = {
    body: "#ddeeff",
    box: "#cde",
    border: "#cde",
    header: "#cde",
    panel: "#ddeeff",
  };

  const cfgColors = config.colorScheme;

  if (!cfgColors || Object.keys(cfgColors).length === 0) {
    return colors;
  }

  Object.keys(cfgColors).forEach((key) => {
    colors[key] = cfgColors[key];
  });

  return colors;
}

export const cfg = buildConfig;

export const ConfigContext = createContext<Config | null>(null);

export const useConfig = (): Config | null => useContext(ConfigContext);
