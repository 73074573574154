import * as React from "react";

import { Config } from "@financialcanvas/util/types";
import { ConfigContext } from "@financialcanvas/util/config";

export default function ConfigProvider({
  children,
  config,
}: {
  children?: React.ReactChild;
  config: Config;
}): JSX.Element | null {
  const configContext = config;

  if (!children) {
    return null;
  }

  return (
    <ConfigContext.Provider value={configContext}>
      {children}
    </ConfigContext.Provider>
  );
}
